<template>
  <ion-modal
      :is-open="modalOpen"
      @didDismiss="closeModal"
      :css-class="isDesktop ? 'modal-web': ''">
    <i-modal :title="$t('Template')+' '+$t('Preview')" @modal-close="closeModal">
      <div v-if="loadingData" class="py-3 text-center">
        <ion-spinner></ion-spinner>
      </div>
      <div v-else class="bg-white p-2 rounded">
        <div v-html="templateData.subject" class="border-bottom pb-1 mb-1 fw-bold text-muted"></div>
        <div v-html="templateData.body"></div>
      </div>
      <template v-slot:footer>
        <nk-button type="light" v-on:click="closeModal">{{$t('Close')}}</nk-button>
      </template>
    </i-modal>
  </ion-modal>
</template>

<script>
import IModal from "@core/components/ion-modal/IModal";
import NkButton from "@core/components/button/NkButton";
import {IonModal, IonSpinner, isPlatform, toastController} from "@ionic/vue"
import useTemplateFunctions from "./eTemplatesFunctions"
import {reactive, ref, watch} from "vue";
import {useI18n} from "vue-i18n";

export default {
  props: {
    modalOpen: Boolean,
    templateId: {
      type: Number,
      required: true,
    },
  },
  components: {NkButton, IModal, IonModal, IonSpinner},
  emits: ['onModalClose'],
  setup( props, {emit}){

    const {t} = useI18n()
    const isDesktop = isPlatform('desktop')
    const{ advisorSendQuickEmailToLead, getTemplateData, templateInitialData } = useTemplateFunctions()
    const closeModal = () => {
      emit('onModalClose')
    }
    let loadingData = ref(false)
    let templateData = reactive(templateInitialData)

    const F_getTemplateData = (id) => {
      loadingData.value = true
      getTemplateData(id)
          .then(resp=>{
            if(!resp.success){
              toastController.create({header: t('Error'), message: resp.message, duration: 3500, color: "danger"}).then((t)=>t.present())
            }
            else{
              for(let x in resp.data){
                templateData[x] = resp.data[x]
              }
            }
          })
          .catch(err => {
            let m = err.response ? err.response.status+' '+err.response.statusText : err
            toastController.create({header: t('Error'), message: m, duration: 3500, color: "danger"}).then((t)=>t.present())
          })
          .then(()=> loadingData.value = false)
    }

    watch(()=> props.templateId, (n)=> {
      if(n){
        F_getTemplateData(props.templateId)
      }
    })

    return {
      advisorSendQuickEmailToLead,
      closeModal,
      isDesktop,
      loadingData,
      templateData,
    }
  }
}
</script>
