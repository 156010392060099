<template>
  <ion-modal
      :is-open="modalOpen"
      @didDismiss="closeModal"
      :css-class="isDesktop ? 'modal-web': ''">
    <i-modal :title="$t('Compose email')" @modal-close="closeModal">
      <form-group>
        <label>{{$t('To')}}</label>
        <b-input type="text" readonly="" :value="sendTo"></b-input>
      </form-group>
      <form-group>
        <label>{{$t('Email Subject')}}</label>
        <b-input type="text" v-model="emailMessage.subject"></b-input>
      </form-group>
      <form-group>
        <label>{{$t('Email Body')}}</label>
        <ckeditor class="border-light border" :editor="InlineEditor" v-model="emailMessage.body" :config="editorConfig"></ckeditor>
      </form-group>
      <template v-slot:footer>
        <nk-button type="primary" class="mr-2" v-on:click="sendEmail">{{$t('Send Email')}}</nk-button>
        <nk-button type="light" v-on:click="closeModal">{{$t('Close')}}</nk-button>
      </template>
    </i-modal>
  </ion-modal>
</template>

<script>
import IModal from "@core/components/ion-modal/IModal";
import NkButton from "@core/components/button/NkButton";
import {IonModal, isPlatform} from "@ionic/vue"
import {reactive, ref} from "vue";
import CKEditor from "@ckeditor/ckeditor5-vue"
import InlineEditor from "@ckeditor/ckeditor5-editor-inline/src/inlineeditor"
import FormGroup from "@core/layouts/form-group/FormGroup";
import BInput from "@core/components/bp-form/components/BInput";
import HeadingPlugin from "@ckeditor/ckeditor5-heading/src/heading";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import EssentialsPlugin from "@ckeditor/ckeditor5-essentials/src/essentials";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";

export default {
  props: {
    modalOpen: Boolean,
    sendTo: {
      type: String,
      required: true,
    }
  },
  components: {
    BInput, FormGroup, NkButton, IModal, IonModal,
    ckeditor: CKEditor.component,
  },
  emits: ['onModalClose', 'onSendEmail'],
  setup( props, {emit}){

    const isDesktop = isPlatform('desktop')
    let emailMessage = reactive({
      subject: '',
      body: '',
    })

    const closeModal = () => {
      emit('onModalClose')
    }
    let loadingData = ref(false)
    const editorConfig = {
      plugins: [BoldPlugin, EssentialsPlugin, ItalicPlugin, LinkPlugin, ParagraphPlugin, HeadingPlugin],
      //removePlugins: ['Heading'],
      toolbar: [ 'bold', 'italic', /*'underline',*/ 'link', /*'alignment'*/ 'heading'],
      heading: {
        options: [
          { model: 'paragraph', title: 'Paragraph', class: 'ck-heading_paragraph' },
          { model: 'heading1', view: 'h1', title: 'Heading 1', class: 'ck-heading_heading1' },
          { model: 'heading2', view: 'h2', title: 'Heading 2', class: 'ck-heading_heading2' },
          { model: 'heading3', view: 'h3', title: 'Heading 3', class: 'ck-heading_heading3' },
          { model: 'heading4', view: 'h4', title: 'Heading 4', class: 'ck-heading_heading4' },
        ]
      }
    }

    const sendEmail = () => {
      emit('onSendEmail', emailMessage)
    }

    return {
      closeModal,
      editorConfig,
      emailMessage,
      InlineEditor,
      isDesktop,
      loadingData,
      sendEmail,
    }
  }
}
</script>
