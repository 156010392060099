<template>
  <ion-modal
      :is-open="modalOpen"
      @didDismiss="closeModal"
      :css-class="isDesktop ? 'modal-web': ''">
    <i-modal :title="$t('Send Email')" @modal-close="closeModal">
      <alert v-if="checkGmailAccess && !$store.state.advisor_data.gmail_access" color="light" has-icon>
        <nio-icon icon="ni-alert-circle"></nio-icon>
        <div>
          <b>{{$t('Permissions Required!')}}</b>
          <p>{{$t('gmail_auth_consent')}}</p>
        </div>
      </alert>
      <template v-else>
        <div v-if="loadingData" class="py-3 text-center">
          <ion-spinner></ion-spinner>
        </div>
        <template v-else>
          <nk-data-list>
            <div class="data-item pt-1 pb-3">
              <div class="data-col d-block">
                <nk-button type="primary" outline round v-on:click="composeMailModal.isModalOpen=true">{{$t('Compose email')}}</nk-button>
                <div class="pt-4 pb-3 px-4">
                  <h6 class="overline-title overline-title-sap"><span>{{$t('OR')}}</span></h6>
                </div>
                <div class="fw-bold">{{$t('Select a template and click the send button')}}</div>
              </div>
            </div>
            <div class="data-item py-1" v-for="eT in eTemplates" :key="'et'+eT.id">
              <div class="data-col d-block">
                <div class="fw-bold text-secondary">{{ eT.name }}</div>
                <div class="text-muted lh-n">{{ eT.info }}</div>
                <div class="mt-1">
                  <a href="javascript:;" v-on:click="[previewModal.templateId=eT.id, previewModal.isModalOpen=true]">{{$t('Preview')}}</a>
                </div>
              </div>
              <div class="data-col data-col-end w-100-px">
                <a href="javascript:;" v-on:click="advisorSendQuickEmailToLead(eT.id, toId)">
                  <span class="data-more h5"><nio-icon icon="ni-send"></nio-icon></span>
                </a>
              </div>
            </div>
          </nk-data-list>
        </template>
      </template>
      <template v-slot:footer>
        <nk-button type="primary" class="mr-2" v-if="checkGmailAccess && !$store.state.advisor_data.gmail_access"
                   v-on:click="isDesktop ? gmailAuthConsentURL() : authenticateWithGoogle()">{{ $t('Grant Permissions') }}</nk-button>
        <nk-button type="secondary" v-on:click="closeModal">{{ $t('Cancel') }}</nk-button>
      </template>
    </i-modal>
  </ion-modal>
  <email-template-preview-modal :template-id="previewModal.templateId" :modal-open="previewModal.isModalOpen" @on-modal-close="previewModal.isModalOpen=false"></email-template-preview-modal>
  <compose-email-modal :modal-open="composeMailModal.isModalOpen"
                       @on-modal-close="composeMailModal.isModalOpen=false"
                       :send-to="recipientEmail"
                       @on-send-email="composeNewEmail"></compose-email-modal>
</template>

<script>
import IModal from "@core/components/ion-modal/IModal";
import NkButton from "@core/components/button/NkButton";
import {IonModal, IonSpinner, isPlatform, loadingController, toastController} from "@ionic/vue"
import Alert from "@core/components/alert/Alert";
import NioIcon from "@core/components/nio-icon/NioIcon";
import useCommonFunctions from "@core/comp-functions/common"
import useTemplateFunctions from "./eTemplatesFunctions"
import {reactive, ref, watch} from "vue";
import NkDataList from "@core/layouts/data-list/NkDataList";
import EmailTemplatePreviewModal from "@/views/email-templates/EmailTemplatePreviewModal";
import {GoogleAuth} from "@codetrix-studio/capacitor-google-auth";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import ComposeEmailModal from "@/views/components/ComposeEmailModal";
import axios from "@/libs/axios";
import useIonComponents from '@core/IonComponents'

export default {
  props: {
    modalOpen: Boolean,
    toId: {
      required: true,
    },
    recipientEmail: null,
    receiverType:{
      type: String,
    },
    checkGmailAccess:{
      type: Boolean,
      default: true,
    },
  },
  components: {
    ComposeEmailModal,
    EmailTemplatePreviewModal, NkDataList, NioIcon, Alert, NkButton, IModal, IonModal, IonSpinner},
  emits: ['onModalClose'],
  setup( props, {emit}){

    const{t} = useI18n()
    const isDesktop = isPlatform('desktop')
    const{ advisorSendQuickEmailToLead, getTemplates } = useTemplateFunctions()
    const{ gmailAuthConsentURL, googleSetAuthCode } = useCommonFunctions()
    const{IonToast} = useIonComponents()
    const store = useStore()

    const authenticateWithGoogle = async () => {

      if(!isPlatform('hybrid')){
        return;
      }
      const response = await GoogleAuth.signIn({
        scopes: [
          'https://www.googleapis.com/auth/gmail.send',
          'https://www.googleapis.com/auth/gmail.labels',
          'https://www.googleapis.com/auth/gmail.compose',
          'https://www.googleapis.com/auth/gmail.modify'
        ]
      });
      if(!response.id){
        return
      }
      const l = await loadingController.create({})
      await l.present()
      googleSetAuthCode(response.serverAuthCode, 'gmail')
          .then(()=>{
            store.commit('commit_general_data', {advisor_data:{gmail_access: true}})
          })
          .catch((er)=>{
            IonToast({
              header: t('Error')+'!', message: er, color: 'danger',position: 'top',
              buttons:[
                {
                  text: t('Cancel'),
                  role:'cancel',
                }
              ]
            })
          })
          .then(() => {
            l.dismiss()
            GoogleAuth.signOut()
          })
    }

    const closeModal = () => {
      emit('onModalClose')
    }
    let loadingData = ref(false)
    let eTemplates = ref([])
    let pagination = reactive({
      page: 0,
      totalRecords: 0,
    })
    const F_getTemplates = async (reset) => {
      loadingData.value = true
      getTemplates({page: pagination.page})
          .then( r => {
            if(reset){
              eTemplates.value = []
            }
            for(let x in r.data){
              eTemplates.value.push(r.data[x]);
            }
            if(pagination.page === 1){
              pagination.totalRecords = r.total_records
            }
          })
          .catch( er => {
            console.log('TemplatesList.vue ajax error: ', er)
          })
          .then(() => loadingData.value = false)
    }

    watch(()=> props.modalOpen, (n)=> {
      if(n && !eTemplates.value.length){
        F_getTemplates(true)
      }
    })

    let previewModal = reactive({
      templateId: 0,
      isModalOpen: false,
    })

    let composeMailModal = reactive({
      to: '',
      isModalOpen: false,
    })

    const composeNewEmail = async (objMessage) => {
      const loader = await loadingController.create({message: t('Sending...')})
      await loader.present()
      axios.post('/api/mail/compose', {receiver_id: props.toId, receiver_type: props.receiverType, subject: objMessage.subject, body: objMessage.body})
      .then((res)=>{
        if(!res.data.success){
          toastController.create({header: t('Error'), message: res.data.message, duration: 4000, position: 'top', color: 'danger'}).then((ts) => ts.present())
        }else{
          composeMailModal.isModalOpen = false
          toastController.create({header: t('Success'), message: t('thank_you_messages.email_sent'), duration: 3000, position: 'top', color: 'primary'}).then((ts) => ts.present())
        }
      })
      .catch((er)=>{
        const msg = er.response ? er.response.status+' '+er.response.statusText : t('errors.general_error')
        toastController.create({header: t('Error'), message: msg, duration: 4000, position: 'top', color: 'danger'}).then((ts) => ts.present())
      })
      .then(()=> loader.dismiss())
    }

    return {
      advisorSendQuickEmailToLead,
      authenticateWithGoogle,
      closeModal,
      composeMailModal,
      composeNewEmail,
      eTemplates,
      isDesktop,
      loadingData,
      gmailAuthConsentURL,
      previewModal,
    }
  }
}
</script>

<style scoped>
.w-100-px{
  width: 100px;
}
</style>