<template>
  <page-template>
    <main-block>
      <block-head :title="$t('Leads')"></block-head>
      <block-content>
        <row class="g-gs">
          <column :xxl="12">

            <card no-padding stretch>
              <template v-slot:grouped>
                <div class="card-inner position-relative card-tools-toggle">
                  <card-header :title="$t('To follow up')">
                    <card-tools>
                      <bulk-action :actions="{export: 'Export'}" @perform-action="performBulkActionTF"></bulk-action>
                    </card-tools>
                    <card-tools>
                      <div class="form-inline flex-nowrap gx-3 d-none d-lg-flex">
                        <div class="form-control-wrap">
                          <div class="input-group">
                            <div class="input-group-prepend mr-1">
                              <label class="text-muted">{{ $t('Sort by') }}</label>
                            </div>
                            <div class="input-group-prepend">
                              <nk-button type="light" outline dim class="rounded-left dropdown-toggle" data-toggle="dropdown">
                                <span>{{ $t(leads.not_followed_up.filters.sort_by) }}</span>
                                <nio-icon icon="ni-chevron-down" class="mx-n1"></nio-icon>
                              </nk-button>
                              <div class="dropdown-menu">
                                <ul class="link-list-opt no-bdr">
                                  <li><a href="javascript:;" v-on:click="[leads.not_followed_up.filters.sort_by='date']">{{ $t('Date') }}</a></li>
                                </ul>
                              </div>
                            </div>
                            <div class="input-group-append">
                              <nk-button type="light" outline dim class="dropdown-toggle" data-toggle="dropdown">
                                <span class="text-capitalize">{{ $t(leads.not_followed_up.filters.sort_order) }}</span>
                                <nio-icon icon="ni-chevron-down" class="mx-n1"></nio-icon>
                              </nk-button>
                              <div class="dropdown-menu">
                                <ul class="link-list-opt no-bdr">
                                  <li v-for="o in ['ASC', 'DESC']" :key="'oo'+o">
                                    <a href="javascript:;" v-on:click="[leads.not_followed_up.filters.sort_order=o]">{{ $t(o) }}</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </card-tools>
                    <card-tools class="mr-n1">
                      <btn-toolbar>
                        <btn-toolbar-toggle-wrap id="advisors_toolbar">
                          <li>
                            <nk-button is-link type=""
                                       is-icon-only-button
                                       class="search-toggle"
                                       v-on:click="leads.not_followed_up.search_bar_active=true"
                                       :class="{active: leads.not_followed_up.search_bar_active}">
                              <nio-icon icon="ni-search"></nio-icon>
                            </nk-button>
                          </li>
                          <li class="d-block d-lg-none">
                            <drop-down
                                icon="ni-sort-fill"
                                size="lg"
                                direction="right"
                                :title="$t('Sort by')">
                              <template v-slot:body>
                                <row class="gx-6 gy-3">
                                  <column default="12" md="7">
                                    <label class="overline-title overline-title-alt" for="sort_by_col">{{ $t('Sort by') }}</label>
                                    <select class="form-control form-control-sm" id="sort_by_col" v-model="leads.not_followed_up.filters.sort_by">
                                      <option value="date">{{ $t('Date') }}</option>
                                    </select>
                                  </column>
                                  <column default="12" md="5">
                                    <label class="overline-title overline-title-alt" for="order">{{ $t('Order') }}</label>
                                    <select class="form-control form-control-sm" id="order" v-model="leads.not_followed_up.filters.sort_order">
                                      <option value="Asc">{{ $t('Asc') }}</option>
                                      <option value="Desc">{{ $t('Desc') }}</option>
                                    </select>
                                  </column>
                                </row>
                              </template>
                            </drop-down>
                          </li>
                        </btn-toolbar-toggle-wrap>
                      </btn-toolbar>
                    </card-tools>
                  </card-header>
                  <card-search
                      :placeholder="$t('Search by')+' '+ $t('Lead') +'#, '+$t('Name')+', '+$t('Email')"
                      :is-active="leads.not_followed_up.search_bar_active"
                      @do-search="searchLeads"
                      :search-term="leads.not_followed_up.filters.search"
                      @close-search="[leads.not_followed_up.search_bar_active=false, searchLeads('')]"></card-search>
                </div>
                <div class="d-flex py-5 justify-center"  v-if="leads.not_followed_up.spinner">
                  <ion-spinner></ion-spinner>
                </div>
                <card-inner v-else class="p-0">
                  <ion-list v-if="$isHybridPlatform">
                    <template v-for="(lead,index) in leads.not_followed_up.leads" :key="'adv_ul_'+lead.AL_id">
                      <ion-item button
                                :color="!lead.opened_at ? 'light': ''"
                                v-on:click="leadActionSheet(lead,index, 'not_followed_up')"
                                lines="full" style="--border-color:#e3eaf5;--padding-bottom: 8px;--padding-top: 8px;">
                        <ion-avatar slot="start" class="user-avatar">
                          <img v-if="lead.dp" :src="lead.dp" alt="Avatar"/>
                          <span v-else class="text-uppercase">{{ lead.first_name.substr(0,1) }}</span>
                        </ion-avatar>
                        <div>
                          <ion-label class="fw-500" color="dark">{{ lead.first_name+' '+lead.last_name}}</ion-label>
                          <ion-text color="medium" class="medium d-block">#{{ lead.lead_number }}</ion-text>
                          <ion-text color="warning" class="medium">{{ $t('Received')+' '+$t('on')+' '+lead.received_time.split(' ')[0] }}</ion-text>
                        </div>
                      </ion-item>
                    </template>
                  </ion-list>
                  <special-table id="advisor_leads_table" v-else>
                    <thead>
                    <table-row is-heading>
                      <table-col has-check-box>
                        <b-check-box id="adv_lsd_tf_s_all_cb" v-model="allSelectedTF"></b-check-box>
                      </table-col>
                      <table-col break-point="md">#ID</table-col>
                      <table-col>{{ $t('Name')}}</table-col>
                      <table-col>{{ $t('Location') }}</table-col>
                      <table-col break-point="lg">{{ $t('Income') }}</table-col>
                      <table-col break-point="md">{{ $t('Status')}}</table-col>
                      <table-col>{{ $t('Actions')}}</table-col>
                    </table-row>
                    </thead>
                    <tbody>
                    <table-row v-for="(lead, index) in leads.not_followed_up.leads" :key="'adv_l'+lead.AL_id" :class="{'bg-secondary-dim': !lead.opened_at}">
                      <table-col has-check-box>
                        <b-check-box :id="'adv_lds_tf_s1_cb'+lead.AL_id" v-model="lead.selected" :key="'adv_lds_tf_s1_cbk'+lead.AL_id"></b-check-box>
                      </table-col>
                      <table-col break-point="md">
                        <strong class="text-success">#{{ lead.lead_number }}</strong>
                        <badge :content="$t('New')" type="primary" v-if="lead.read_status === 0" class="ml-1"></badge>
                        <span class="d-block text-muted small font-weight-bold">{{ lead.received_date }}</span>
                        <span class="d-block text-muted small font-weight-bold">{{ lead.received_time }}</span>
                      </table-col>
                      <table-col>
                        <user-small-card :user-name="lead.first_name+' '+lead.last_name" initials="S" :user-email="lead.email">
                          <span class="d-block small">{{ lead.form_name }}</span>
                          <template v-slot:more_info>
                            <div class="mt-n1 d-block d-md-none small">
                              <div class="text-primary">#{{ lead.lead_number }}</div>
                              <div>{{ $t('Received')+' '+$t('on')+' '+lead.received_time.split(' ')[0] }}</div>
                            </div>
                          </template>
                        </user-small-card>
                      </table-col>
                      <table-col class="text-muted">
                        <span>{{ lead.city}}, {{ lead.province }}</span>
                        <span class="d-block">{{ lead.post_code }}</span>
                      </table-col>
                      <table-col class="text-muted" break-point="lg">
                        &euro;{{ lead.income }}
                      </table-col>
                      <table-col break-point="md">
                        <span :style="{color: lead.status_color}">{{ $t(lead.status_text) }}</span>
                        <template v-if="['follow up', 'appointment'].includes(lead.status_text.toLowerCase())">
                          <span class="d-block text-muted small fw-500">{{ $t('on') +' '+ lead.event_date+' '+$t('at')+' '+lead.event_time}}</span>
                        </template>
                      </table-col>
                      <table-col break-point="md">
                        <tb-actions class="justify-start">
                          <li>
                            <nk-button size="sm" type="success" :is-icon-only-button="true">
                              <nio-icon icon="ni-call"></nio-icon>
                            </nk-button>
                          </li>
                          <li>
                            <nk-button size="sm" type="success" is-icon-only-button v-on:click="initQuickEmail(lead)">
                              <nio-icon icon="ni-mail"></nio-icon>
                            </nk-button>
                          </li>
                          <li>
                            <nk-button size="sm" type="success" is-icon-only-button v-on:click="$router.push({name: 'LeadDetails', params:{ id: lead.AL_id}})">
                              <nio-icon icon="ni-eye"></nio-icon>
                            </nk-button>
                          </li>
                          <li>
                            <nk-button size="sm" type="warning" is-icon-only-button v-on:click="F_archiveLead('not_followed_up', lead.AL_id, index)" :title="$t('Archive this lead')">
                              <nio-icon icon="ni-archived"></nio-icon>
                            </nk-button>
                          </li>
                        </tb-actions>
                      </table-col>
                      <table-col class="nk-tb-col-tools d-md-none">
                        <ul class="nk-tb-actions gx-1">
                          <li>
                            <drop-down-list>
                              <list-item icon="ni-mail" :text="$t('Email')" v-on:click="initQuickEmail(lead)"></list-item>
                              <list-item icon="ni-eye" :text="$t('View Details')" :to="{name: 'LeadDetails', params:{ id: lead.AL_id}}"></list-item>
                              <list-item icon="ni-archived" :text="$t('Archive')" v-on:click="F_archiveLead('not_followed_up', lead.AL_id, index)"></list-item>
                            </drop-down-list>
                          </li>
                        </ul>
                      </table-col>
                    </table-row>
                    </tbody>
                  </special-table>
                </card-inner>
                <div class="card-inner" v-if="leads.not_followed_up.total_records > 10">
                  <pagination
                      v-model="leads.others.page"
                      :records="leads.others.total_records"
                      :per-page="10"
                      @paginate="paginationCallback"
                      theme="bootstrap4"
                      :options="{chunk: 5, texts:{count: '||'}}"/>
                </div>
              </template>
            </card>

            <card no-padding stretch>
              <template v-slot:grouped>
                <div class="card-inner position-relative card-tools-toggle">
                  <card-header :title="$t('Other leads')">
                    <card-tools>
                      <bulk-action :actions="{export: 'Export'}" @perform-action="performBulkActionOT"></bulk-action>
                    </card-tools>
                    <card-tools>
                      <div class="form-inline flex-nowrap gx-3 d-none d-lg-flex">
                        <div class="form-control-wrap">
                          <div class="input-group">
                            <div class="input-group-prepend mr-1">
                              <label class="text-muted">{{ $t('Sort by') }}</label>
                            </div>
                            <div class="input-group-prepend">
                              <nk-button type="light" outline dim class="rounded-left dropdown-toggle" data-toggle="dropdown">
                                <span class="text-capitalize">{{ $t(leads.others.sort_by_list[leads.others.filters.sort_by]) }}</span>
                                <nio-icon icon="ni-chevron-down" class="mx-n1"></nio-icon>
                              </nk-button>
                              <div class="dropdown-menu">
                                <ul class="link-list-opt no-bdr">
                                  <li v-for="(s,i) in leads.others.sort_by_list" :key="'ols'+i">
                                    <a href="javascript:;" v-on:click="[leads.others.filters.sort_by=i]">{{ $t(s) }}</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                            <div class="input-group-append">
                              <nk-button type="light" outline dim class="dropdown-toggle" data-toggle="dropdown">
                                <span class="text-capitalize">{{ $t(leads.others.filters.sort_order) }}</span>
                                <nio-icon icon="ni-chevron-down" class="mx-n1"></nio-icon>
                              </nk-button>
                              <div class="dropdown-menu">
                                <ul class="link-list-opt no-bdr">
                                  <li v-for="o in ['ASC', 'DESC']" :key="'oo'+o">
                                    <a href="javascript:;" v-on:click="[leads.others.filters.sort_order=o]">{{ $t(o) }}</a>
                                  </li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </card-tools>
                    <card-tools class="mr-n1">
                      <btn-toolbar>
                        <btn-toolbar-toggle-wrap id="advisors_toolbar">
                          <li>
                            <nk-button is-link type=""
                                       is-icon-only-button
                                       class="search-toggle"
                                       v-on:click="leads.others.search_bar_active=true"
                                       :class="{active: leads.others.search_bar_active}">
                              <nio-icon icon="ni-search"></nio-icon>
                            </nk-button>
                          </li>
                          <li class="btn-toolbar-sep"></li>
                          <li>
                            <drop-down
                                icon="ni-filter-alt"
                                size="lg"
                                direction="right"
                                :dot="( leads.others.filters.status !== -1 ? 'primary':'')"
                                :title="'Leads '+$t('Filter')">
                              <template v-slot:body>
                                <row class="gx-6 gy-3">
                                  <column default="12" lg="8">
                                    <label class="overline-title overline-title-alt" for="status_filter">{{ $t('Status') }}</label>
                                    <select class="form-control form-control-sm" id="status_filter" v-model="leads.others.filters.status">
                                      <option value="-1">{{ $t('All') }}</option> <!-- -1 means, exclude the 1 status which is "Contact lead" -->
                                      <template v-for="ls in leadStatuses" :key="'ls'+ls.id">
                                        <option :value="ls.id" v-if="parseInt(ls.id) !== 1">{{ $t(ls.status_text) }}</option>
                                      </template>
                                    </select>
                                  </column>
                                </row>
                              </template>
                              <template v-slot:footer>
                                <nk-button type="dark" dim v-on:click="getOtherLeads(true)">{{ $t('Filter') }}</nk-button>
                                <a href="javascript:;" v-on:click="[leads.others.status_filter='',getOtherLeads(true)]">{{ $t('Reset Filter') }}</a>
                              </template>
                            </drop-down>
                          </li>
                          <li class="d-block d-lg-none">
                            <drop-down
                                icon="ni-sort-fill"
                                size="lg"
                                direction="right"
                                :title="$t('Sort by')">
                              <template v-slot:body>
                                <row class="gx-6 gy-3">
                                  <column default="12" md="7">
                                    <label class="overline-title overline-title-alt" for="sort_by_col1">{{ $t('Sort by') }}</label>
                                    <select class="form-control form-control-sm" id="sort_by_col1" v-model="leads.others.filters.sort_by">
                                      <option v-for="(c,i) in leads.others.sort_by_list" :key="'sbc'+i" :value="i">{{ $t(c) }}</option>
                                    </select>
                                  </column>
                                  <column default="12" md="5">
                                    <label class="overline-title overline-title-alt" for="order1">{{ $t('Order') }}</label>
                                    <select class="form-control form-control-sm" id="order1" v-model="leads.others.filters.sort_order">
                                      <option value="Asc">{{ $t('Asc') }}</option>
                                      <option value="Desc">{{ $t('Desc') }}</option>
                                    </select>
                                  </column>
                                </row>
                              </template>
                            </drop-down>
                          </li>
                        </btn-toolbar-toggle-wrap>
                      </btn-toolbar>
                    </card-tools>
                  </card-header>
                  <card-search
                      :placeholder="$t('Search by')+' '+ $t('Lead') +'#, '+$t('Name')+', '+$t('Email')"
                      :is-active="leads.others.search_bar_active"
                      @do-search="searchLeads1"
                      :search-term="leads.others.filters.search"
                      @close-search="[leads.others.search_bar_active=false, searchLeads('')]"></card-search>
                </div>
                <div class="d-flex py-5 justify-center"  v-if="leads.others.spinner">
                  <ion-spinner></ion-spinner>
                </div>
                <card-inner v-else class="p-0">
                  <ion-list v-if="$isHybridPlatform">
                    <template v-for="(lead,index) in leads.not_followed_up.leads" :key="'adv_ol_'+lead.AL_id">
                      <ion-item button
                                :color="!lead.opened_at ? 'light': ''"
                                v-on:click="leadActionSheet(lead,index, 'others')"
                                lines="full" style="--border-color:#e3eaf5;--padding-bottom: 8px;--padding-top: 8px;">
                        <ion-avatar slot="start" class="user-avatar">
                          <img v-if="lead.dp" :src="lead.dp" alt="Avatar"/>
                          <span v-else class="text-uppercase">{{ lead.first_name.substr(0,1) }}</span>
                        </ion-avatar>
                        <div>
                          <ion-label class="fw-500" color="dark">{{ lead.first_name+' '+lead.last_name}}</ion-label>
                          <ion-text color="medium" class="medium d-block">#{{ lead.lead_number }}</ion-text>
                          <ion-text :style="{color: lead.status_color}" class="medium">{{ $t(lead.status_text) }}</ion-text>
                        </div>
                        <ion-badge color="tertiary" slot="end" v-if="lead.read_status === 0">{{ $t('New')}}</ion-badge>
                      </ion-item>
                    </template>
                  </ion-list>
                  <special-table id="advisor_leads_table" v-else>
                    <thead>
                    <table-row is-heading>
                      <table-col has-check-box>
                        <b-check-box id="adv_lds_otl_s_all_cb" v-model="allSelectedOT"></b-check-box>
                      </table-col>
                      <table-col break-point="md">#ID</table-col>
                      <table-col>{{ $t('Name')}}</table-col>
                      <table-col>{{ $t('Location') }}</table-col>
                      <table-col break-point="lg">{{ $t('Income') }}</table-col>
                      <table-col>{{ $t('Status')}}</table-col>
                      <table-col>{{ $t('Actions')}}</table-col>
                    </table-row>
                    </thead>
                    <tbody>
                    <table-row v-for="(lead, index) in leads.others.leads" :key="'adv_l'+lead.AL_id" :class="{'bg-secondary-dim': !lead.opened_at}">
                      <table-col has-check-box>
                        <b-check-box :id="'adv_lds_otl_s1_cb'+lead.AL_id" v-model="lead.selected" :key="'adv_lds_otl_s1_cbk'+lead.AL_id"></b-check-box>
                      </table-col>
                      <table-col break-point="md">
                        <strong class="text-success">#{{ lead.lead_number }}</strong>
                        <badge :content="$t('New')" type="primary" v-if="lead.read_status === 0" class="ml-1"></badge>
                        <span class="d-block text-muted small font-weight-bold">{{ lead.received_date }}</span>
                        <span class="d-block text-muted small font-weight-bold">{{ lead.received_time }}</span>
                      </table-col>
                      <table-col>
                        <user-small-card :user-name="lead.first_name+' '+lead.last_name" initials="S" :user-email="lead.email">
                          <span class="d-block small">{{ lead.form_name }}</span>
                          <template v-slot:more_info>
                            <div class="mt-n1 d-block d-md-none small">
                              <div class="text-primary">#{{ lead.lead_number }}</div>
                              <div>{{ $t('Received')+' '+$t('on')+' '+lead.received_time.split(' ')[0] }}</div>
                            </div>
                          </template>
                        </user-small-card>
                      </table-col>
                      <table-col class="text-muted">
                        <span>{{ lead.city}}, {{ lead.province }}</span>
                        <span class="d-block">{{ lead.post_code }}</span>
                      </table-col>
                      <table-col class="text-muted" break-point="lg">
                        &euro;{{ lead.income }}
                      </table-col>
                      <table-col>
                        <span :style="{color: lead.status_color}">{{ $t(lead.status_text) }}</span>
                        <template v-if="['follow up', 'appointment'].includes(lead.status_text.toLowerCase())">
                          <span class="d-block text-muted small fw-500">{{ $t('on') +' '+ lead.event_date+' '+$t('at')+' '+lead.event_time}}</span>
                        </template>
                      </table-col>
                      <table-col break-point="md">
                        <tb-actions class="justify-start">
                          <li>
                            <nk-button size="sm" type="success" :is-icon-only-button="true">
                              <nio-icon icon="ni-call"></nio-icon>
                            </nk-button>
                          </li>
                          <li>
                            <nk-button size="sm" type="success" is-icon-only-button v-on:click="initQuickEmail(lead)">
                              <nio-icon icon="ni-mail"></nio-icon>
                            </nk-button>
                          </li>
                          <li>
                            <nk-button size="sm" type="success" is-icon-only-button v-on:click="$router.push({name: 'LeadDetails', params:{ id: lead.AL_id}})">
                              <nio-icon icon="ni-eye"></nio-icon>
                            </nk-button>
                          </li>
                          <li>
                            <nk-button size="sm" type="warning" is-icon-only-button v-on:click="F_archiveLead('others', lead.AL_id, index)" :title="$t('Archive this lead')">
                              <nio-icon icon="ni-archived"></nio-icon>
                            </nk-button>
                          </li>
                        </tb-actions>
                      </table-col>
                      <table-col class="nk-tb-col-tools d-md-none">
                        <ul class="nk-tb-actions gx-1">
                          <li>
                            <drop-down-list>
                              <list-item icon="ni-mail" :text="$t('Email')" v-on:click="initQuickEmail(lead)"></list-item>
                              <list-item icon="ni-eye" :text="$t('View Details')" :to="{name: 'LeadDetails', params:{ id: lead.AL_id}}"></list-item>
                              <list-item icon="ni-archived" :text="$t('Archive')" v-on:click="F_archiveLead('others', lead.AL_id, index)"></list-item>
                            </drop-down-list>
                          </li>
                        </ul>
                      </table-col>
                    </table-row>
                    </tbody>
                  </special-table>
                </card-inner>
                <div class="card-inner" v-if="leads.others.total_records > 10">
                  <pagination
                      v-model="leads.others.page"
                      :records="leads.others.total_records"
                      :per-page="10"
                      @paginate="paginationCallback1"
                      theme="bootstrap4"
                      :options="{chunk: 5, texts:{count: '||'}}"/>
                </div>
              </template>
            </card>
          </column>
        </row>
      </block-content>
    </main-block>
    <send-quick-email :to-id="quickEmail.to" :modal-open="quickEmail.isModalOpen"
                      @on-modal-close="quickEmail.isModalOpen=false"
                      :recipient-email="quickEmail.toEmail"></send-quick-email>
    <leads-csv-export-modal
        :selected-leads="leadsCSVExport.selectedLeads"
        :modal-open="leadsCSVExport.modalOpen"
        @modal-close="leadsCSVExport.modalOpen=false"></leads-csv-export-modal>
  </page-template>
</template>

<script>
import {
  onIonViewWillEnter,
  IonList,
  IonItem,
  IonAvatar,
  IonLabel,
  IonText,
  IonBadge,
  IonSpinner,
  actionSheetController
} from '@ionic/vue';
import {computed, defineComponent, reactive, watch} from 'vue';
import UserSmallCard from "@/views/components/user-cards/UserSmallCard";
import { BlockContent, BlockHead, MainBlock, Row, Column} from '@core/layouts';
import { Card, CardHeader, CardInner, CardSearch, CardTools } from '@core/components/cards'
import axios from "@/libs/axios";
import { TbActions } from '@core/components/data-tables'
import useDataChangeTracker from "@/store/data-change-tracker/dataChangeTracker"
import {useStore} from "vuex"

import "bootstrap"
import SendQuickEmail from "@/views/email-templates/SendQuickEmail";
import useLeadFunctions from "@core/comp-functions/LeadFunctions"
import {SpecialTable, TableCol, TableRow} from "@core/components/special-table";
import {BtnToolbar, BtnToolbarToggleWrap} from "@core/components/btn-toolbar";
import {Badge, DropDown, DropDownList} from "@core/components";
import Pagination from "v-pagination-3"
import ListItem from "@core/components/dropdown-list/ListItem";
import {useRouter} from "vue-router";
import {useI18n} from "vue-i18n";
import PageTemplate from "@core/layouts/page/PageTemplate";
import BulkAction from "@/views/components/BulkAction";
import BCheckBox from "@core/components/bp-form/components/BCheckBox";
import useCommon from '@core/comp-functions/common'
import LeadsCsvExportModal from "@/views/leads-export/components/LeadsCsvExportModal";

export default defineComponent({
  components: {
    LeadsCsvExportModal,
    BCheckBox,
    BulkAction,
    PageTemplate,
    ListItem,
    DropDownList,
    CardSearch,
    DropDown,
    BtnToolbarToggleWrap,
    BtnToolbar,
    CardTools,
    BlockHead,
    CardInner,
    TableCol,
    TableRow,
    TbActions,
    SpecialTable,
    SendQuickEmail,
    Badge,
    CardHeader,
    Card,
    Row,
    Column,
    BlockContent,
    MainBlock,
    Pagination,
    UserSmallCard,
    IonList, IonItem, IonAvatar, IonLabel, IonText, IonBadge, IonSpinner,
  },
  setup() {

    const {updateTracker} = useDataChangeTracker()
    const{archiveLead} = useLeadFunctions()
    const{getSelectedRecordsForBulkAction, selectAll} = useCommon()
    const store = useStore()
    const router = useRouter()
    const{t} = useI18n()
    let leads = reactive({
      not_followed_up: {
        leads: [],
        total_records: 0,
        spinner: false,
        search_bar_active: false,
        filters:{
          archived: 0,
          page: 1,
          search: '',
          sort_by: 'date',
          sort_order: 'DESC',
          status: 1,
        }
      },
      others:{
        leads: [],
        total_records: 0,
        sort_by_list: {
          event_date: 'Follow up date',
          date: 'Receive date',
        },
        spinner: false,
        search_bar_active: false,
        filters:{
          archived: 0,
          page: 1,
          search: '',
          sort_by: 'event_date',
          sort_order: 'DESC',
          status: -1, // -1 means exclude the 1 (Contact lead) status code, those leads are already listed in the above not_followed_up leads
        }
      }
    })
    let leadsCSVExport= reactive({
      modalOpen: false,
      selectedLeads: [],
    })

    const paginationCallback1 = async () => {
      await getOtherLeads()
    }
    const paginationCallback = async () => {
      await getLeadsNotFollowedUp()
    }

    const getLeadsNotFollowedUp = (reset) => {
      if(reset){
        leads.not_followed_up.page = 1
      }
      leads.not_followed_up.spinner = true
      leads.not_followed_up.leads.splice(0)
      let d = Object.assign({}, leads.not_followed_up.filters)
      d.limit = 10
      axios.post('/api/advisor/leads', d)
          .then((resp) => {
            if(resp.data.success){
              for(let x in resp.data.data){
                resp.data.data[x].selected = false
                leads.not_followed_up.leads.push(resp.data.data[x])
              }
              if(leads.not_followed_up.page === 1){
                leads.not_followed_up.total_records = resp.data.total_records
              }
            }
          })
          .catch(err => {
            console.log('LeadsList.vue getLeadsNotFollowedUp() ajax error: ', err)
          })
      .then(()=> leads.not_followed_up.spinner = false)
    }
    const getOtherLeads = (reset) => {
      if(reset){
        leads.others.page = 1
      }
      leads.others.spinner = true
      let d = Object.assign({}, leads.others.filters)
      d.limit = 10
      axios.post('/api/advisor/leads', d)
          .then((resp) => {
            if(resp.data.success){
              leads.others.leads = resp.data.data
              if(leads.others.page === 1){
                leads.others.total_records = resp.data.total_records
              }
            }
          })
          .catch(err => {
            console.log('LeadsList.vue getOtherLeads() ajax error: ', err)
          })
      .then(()=> leads.others.spinner = false)
    }

    onIonViewWillEnter(()=> {
      getLeadsNotFollowedUp()
      getOtherLeads()
    })

    // Update leads list, in case a lead get changed in other components
    watch(() => store.state.dataChangeTracker.advisor.leads_list, (n) => {
      if(n){
        updateTracker('advisor', ['leads_list'], false) // reset tracker
        getOtherLeads()
        getLeadsNotFollowedUp()
      }
    })
    watch( leads.others.filters, () => {
      getOtherLeads(true)
    })
    watch(leads.not_followed_up.filters, ()=>{
      getLeadsNotFollowedUp(true)
    })

    let quickEmail = reactive({
      to: 0,
      isModalOpen: false,
      toEmail: '',
    })

    const F_archiveLead = (listType, AL_id, index) => {
      archiveLead(AL_id)
      .then(()=>{
        let list = listType === 'not_followed_up' ? leads.not_followed_up.leads : leads.others.leads
        // Decrement badge.
        if(store.state.menu_badges_data.LeadsList > 0 && !list[index].opened_at){
          store.commit('commit_menu_badges', {key: 'LeadsList', value: (store.state.menu_badges_data.LeadsList-1) })
        }
        list.splice(index,1)
      })
      .catch(()=>{})
    }

    const searchLeads = (search) => {
      leads.not_followed_up.filters.search = search
    }
    const searchLeads1 = (search) => {
      leads.others.filters.search = search
    }

    const leadActionSheet = async (lead, index, listType) => {
      const actionSheet = await actionSheetController.create({
        header: t('Choose an action'),
        buttons: [
          {
            text: t('Call'),
            handler:()=> window.open('tel:'+lead.phone_no, '_self')
          },
          {
            text: t('Email'),
            handler:()=>{
              initQuickEmail(lead)
            }
          },
          {
            text: t('View details'),
            handler: ()=>{
              router.push({name: 'LeadDetails', params:{ id: lead.AL_id }})
            }
          },
          {
            text: t('Archive'),
            role: 'destructive',
            handler:()=> F_archiveLead(listType, lead.AL_id, index)
          },
        ],
      });
      await actionSheet.present();
    };

    const initQuickEmail = (lead)=>{
      quickEmail.to = lead.AL_id
      quickEmail.toEmail = lead.email
      quickEmail.isModalOpen= true
    }

    const allSelectedTF = computed({
      get:()=> {
        for(let x in leads.not_followed_up.leads){
          if(!leads.not_followed_up.leads[x].selected){
            return false
          }
        }
        return true
      },
      set: (s)=> selectAll(leads.not_followed_up.leads, s)
    })
    const allSelectedOT = computed({
      get:()=> {
        for(let x in leads.others.leads){
          if(!leads.others.leads[x].selected){
            return false
          }
        }
        return true
      },
      set: (s)=> selectAll(leads.others.leads, s)
    })

    const performBulkAction = (listType, action)=>{
      const list = getSelectedRecordsForBulkAction(listType === 'other' ? leads.others.leads : leads.not_followed_up.leads)
      if(!list){
        return;
      }
      if( action === 'export' ){
        leadsCSVExport.selectedLeads.splice(0)
        for(let x in list) leadsCSVExport.selectedLeads.push(list[x].AL_id)
        leadsCSVExport.modalOpen = true
      }
    }
    const performBulkActionOT = (action)=>{
      performBulkAction('other', action)
    }
    const performBulkActionTF = (action)=>{
      performBulkAction('not_followed_up', action)
    }

    return {
      allSelectedOT,
      allSelectedTF,
      F_archiveLead,
      getLeadsNotFollowedUp,
      getOtherLeads,
      initQuickEmail,
      leadActionSheet,
      leadStatuses: computed(()=> store.state.general_data.leads_statuses),
      leads,
      leadsCSVExport,
      paginationCallback,
      paginationCallback1,
      performBulkActionOT,
      performBulkActionTF,
      quickEmail,
      searchLeads,
      searchLeads1,
    }
  }
});
</script>
